import React, { useState, createRef, useEffect, useContext } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../Demo.css";
import { FilterContext } from "../../../App";
import { Box, Button } from "@mui/material";


export const CropImage = (props) => {
  const cropperRef = createRef();//<ReactCropperElement>

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      props.setImageFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <div>
      <Box sx={{ width: "100%", marginBottom:'15px' }}>
        <Cropper id="canvasId"
          // onWheel={onWheel}
          // onmousewheel={onWheel}
          src={props.imageFile}
          ref={cropperRef}
          style={{ maxHeight: 400, width: "100%" }}
          zoomTo={0}
          initialAspectRatio={1}
          preview=".img-preview"
          
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: "center" }}>
        <Button variant="outlined" onClick={getCropData} >Crop Image</Button>
      </Box>
    </div>
  );
};

export default CropImage;
