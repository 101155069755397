import { Box, Button, Grid } from "@mui/material"
import { styled } from '@mui/system';
import { useContext, useRef, useState } from "react";
import { FilterContext } from "../App";
import "../styles/instagram.scss";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

const StyleBox = styled(Box)({
  background: '#ddd',
  minHeight: '20rem',
  maxHeight: '100%',
  marginBottom: '1rem',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const StyledImage = styled('img')(props => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  filter: `contrast(${props.customFilter.contrast}%) brightness(${props.customFilter.brightness}%) saturate(${props.customFilter.saturate}%) sepia(${props.customFilter.sepia}%) grayScale(${props.customFilter.gray}%) hue-rotate(${props.customFilter.huerotate}deg) blur(${props.customFilter.blur/10}px)`,
  // transform: `scaleX(${props.customFilter.scaleX}) scaleY(${props.customFilter.scaleY}) rotate(${props.customFilter.rotate}deg)`,
  // '-webkit-transform':`rotate(${props.customFilter.rotate}deg)`
}))

const ImageField = () => {
  const uploadInputRef = useRef(null);
  const imgResultRef = useRef(null);
  // const [imageFile, setImageFile] = useState(null);
  const { filterClass, customFilter,imageFile, setImageFile } = useContext(FilterContext);

  const handleChangeInput = (e) => {
    setImageFile(URL.createObjectURL(e.target.files[0]))
  }

  const handleHQDownloadImage = () => {
    // domtoimage.toBlob(imgResultRef.current)//imgResultRef.current
    debugger;
    var scale = 5;
domtoimage.toBlob(imgResultRef.current, {
 width: imgResultRef.current.clientWidth * scale,
 height: imgResultRef.current.clientHeight * scale,
}).
then(function (blob) {
  saveAs(blob, 'result.png');
})
    .catch(function (error) {
      console.error('ooops, something went wrong!', error)
    });
  }

  const handleLQDownloadImage = () => {
    debugger;
    var scale = 1;
domtoimage.toBlob(imgResultRef.current, {
 width: imgResultRef.current.clientWidth * scale,
 height: imgResultRef.current.clientHeight * scale,
}).
then(function (blob) {
  saveAs(blob, 'result.png');
})
    .catch(function (error) {
      console.error('ooops, something went wrong!', error)
    });
  }

//   const handleDownloadImage1 = () => {
//     let element = document.getElementById('myimg')
//     let scale = 2;

//     domtoimage.toPng(imgResultRef.current, {
//             width: imgResultRef.current.clientWidth * scale,
//             height: imgResultRef.current.clientHeight * scale,
//             style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}
//         })
//         .then(function (dataUrl) { appendImg(dataUrl) })
//         .catch(function (error) { console.error('oops, something went wrong!', error); });
// }

  const renderImage = () => (
    // figure =>style= width: '100%', height: '100%' 
    <figure className="frame">
      <StyledImage customFilter={!filterClass && customFilter} className={filterClass} src={imageFile} alt="" ref={imgResultRef} />
    </figure>
  )

  return (
    <Grid item xs={12} md={7}>
      
      <Button onClick={handleHQDownloadImage} disabled={!imageFile} variant="contained" fullWidth>Download High Quality Image</Button>
      <br />
      <Button onClick={handleLQDownloadImage} disabled={!imageFile} variant="contained" fullWidth>Download Low Quality Image</Button>
      
      <StyleBox
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
      >
        {imageFile ? renderImage() : <p>Upload Image</p>}
      </StyleBox>
      <input onChange={handleChangeInput} ref={uploadInputRef} type="file" accept="image/*" hidden />
      
    </Grid>
  )
}

export default ImageField