const InitialCustomFilterState = {
  contrast: 100,
  brightness: 100,
  saturate: 100,
  sepia: 0,
  gray: 0,
  huerotate: 0,
  blur: 0,
  scaleX: 1,
  scaleY: 1,
  rotate: 0
}

export default InitialCustomFilterState