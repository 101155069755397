import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useContext } from "react"
import { FilterContext } from "../App";
import { filterValues } from '../utils';
import  './InstaFitler.scss';
import $ from 'jquery'; 
import { styled } from '@mui/system';

const InstaFitler = () => {
  const {filterClass, setFilterClass, customFilter} = useContext(FilterContext);

  const handleChange = (e) => {
    setFilterClass(e.target.value)
  }
  const handleClick = (e) => {
    setFilterClass(e.target.classList[0])
  }

  const scroll = (direction) => {
    let far = $( '.imageContainer' ).width()/2*direction;
    let pos = $('.imageContainer').scrollLeft() + far;
    $('.imageContainer').animate( { scrollLeft: pos }, 1000)
  }


  const StyledImage = styled('img')(props => ({
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
    // filter: `contrast(${props.customFilter.contrast}%) brightness(${props.customFilter.brightness}%) saturate(${props.customFilter.saturate}%) sepia(${props.customFilter.sepia}%) grayScale(${props.customFilter.gray}%) hue-rotate(${props.customFilter.huerotate}deg) blur(${props.customFilter.blur/10}px)`,
  }))

  return (
    <Box>
      {/* <FormControl fullWidth>
        <InputLabel>Filter</InputLabel>
        <Select
          onChange={handleChange}
          value={filterClass}
          label="Filter"
        >
          {filterValues.map(filter => <MenuItem value={filter.class} key={filter.class}>{filter.name}</MenuItem>)}
        </Select>
      </FormControl> */}

      <div className='main'>
      {/* <h1 className='title'>Scrolling Image Slider</h1> */}
      <div className='wrapper'>
        {/* <a className='prev' onClick={scroll.bind(null,-1)}>&#10094;</a> */}
        <div className='imageContainer'>
        {filterValues.map(filter => 
        <div className='image'  key={filter.class}>   
        <StyledImage onClick={handleClick} className={filter.class} src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj3oepl7TJmZJwds6SU5mnfFIC6N-8LNBNhbx8AgNO5ITyDJm4Fbknzv8dZrClbMRbhyU&usqp=CAU'} alt=""/>
        <InputLabel>{filter.name}</InputLabel>
        </div>)}
          {/* <div className='image'>   <StyledImage className={filterClass} src={'https://www.befunky.com/images/app/sample-images/blue-vw-van-thumb.jpg?width=144'} alt=""/></div> */}
          {/* <div className='image'>2</div>
          <div className='image'>3</div>
          <div className='image'>4</div>
          <div className='image'>5</div>
          <div className='image'>6</div>
          <div className='image'>7</div>
          <div className='image'>8</div>
          <div className='image'>9</div>
          <div className='image'>10</div> */}
        </div>
        {/* <a className='next' onClick={scroll.bind(null,1)}>&#10095;</a> */}
      </div>
    </div>

    </Box>
  )
}

export default InstaFitler