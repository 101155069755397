import { Box, Button, Container, Grid, InputLabel } from "@mui/material"
import CustomFilter from "../CustomFilter"
import FilterTabs from "../FilterTabs"
import ImageField from "../ImageField"
import InstaFitler from "../InstaFitler"
import { useContext } from "react"
import { FilterContext } from './../../App';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import CropImage from "./CropImage"
import RotateAndFlipImage from "./RotateAndFlipImage"

function SingleImageEditor() {
  let { tabFilter } = useContext(FilterContext);

  return (
    <Container id="SIE" sx={{ marginTop: '1rem', marginBottom: '4rem', padding: { xs: 0, sm: '10px' } }}>
      <Box sx={{ textAlign: 'left', marginBottom: '1rem', marginTop: '0rem', padding: '0rem', color: '#1976d2' }}>
        <h2># Single Image Editor</h2>
      </Box>
      <Grid container spacing={2} >
        <ImageField />
        <Grid item xs={12} md={5} >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
              <FilterTabs />
              {tabFilter === 'instaFilter' ? <InstaFitler /> : <CustomFilter />}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Crop</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CropImage></CropImage>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Rotate and Flip</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RotateAndFlipImage></RotateAndFlipImage>
            </AccordionDetails>
          </Accordion> */}
        </Grid>
      </Grid>
    </Container>
  )
}

export default SingleImageEditor
