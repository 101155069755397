import { Box, Button } from "@mui/material"
import SliderField from "./SliderField"
import { useContext, useState } from "react"
import { FilterContext } from "../App";
import InitialCustomFilterState from "./InitialCustomFilterState"

const CustomFilter = () => {
  let { customFilter, setCustomFilter } = useContext(FilterContext);
  const [reloadKey, setReloadKey] = useState(0)
  let slider = [
    { label: 'Contrast', defaultValue: customFilter.contrast, field: 'contrast', max: 200 },
    { label: 'Brightness', defaultValue: customFilter.brightness, field: 'brightness', max: 200 },
    { label: 'Saturation', defaultValue: customFilter.saturate, field: 'saturate', max: 200 },
    { label: 'Sepia', defaultValue: customFilter.sepia, field: 'sepia', max: 200 },
    { label: 'Gray Scale', defaultValue: customFilter.gray, field: 'gray', max: 200 },
    { label: 'Hue-Rotate', defaultValue: customFilter.huerotate, field: 'huerotate', max: 360 },
    { label: 'Blur', defaultValue: customFilter.blur, field: 'blur', max: 100 },
  ]

  const handleResetCustomFilter = () => {
    setCustomFilter(InitialCustomFilterState);
    setReloadKey(reloadKey + 1);
  };

  return (
    <>
      <Box sx={{ marginTop: '2rem', padding: { xs: '2em', md: '1em' }, maxHeight: { xs: "200px", md: '100%' }, overflowY: 'auto' }} key={reloadKey}>
        {slider.map(slide => <SliderField slide={slide} key={slide.field} />)}

      </Box>

      <Button onClick={handleResetCustomFilter} variant="contained" fullWidth>Reset Custom Filter</Button>

    </>
  )
}

export default CustomFilter