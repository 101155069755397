import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { styled } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import CropImage from './CropImage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import $ from 'jquery';

const StyleBox = styled(Box)({
  background: '#ddd',
  minHeight: '20rem',
  maxHeight: '100%',
  // minWeight: '100%',
  marginBottom: '1rem',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});


export default function PassportPhotoGeneration() {
  const [imageFile, setImageFile] = useState(null);
  const uploadInputRef = useRef(null);
  const uploadInputRef2 = useRef(null);


  const [ppSize, setPPSize] = React.useState('3cm X 4cm');
  const [ppSheetSize, setPPSheetSize] = React.useState('8 X 12 inch Sheet');

  const handlePPSizeChange = (event) => {
    setPPSize(event.target.value);
  };

  const handlePPSheetSizeChange = (event) => {
    setPPSheetSize(event.target.value);
  };

  const [resultImage, setResultImage] = useState(null);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  // // Image
  // const [image, setImage] = useState({
  //   imageUrl: false,
  //   brightness: 100,
  //   contrast: 100,
  //   saturate: 100,
  //   hueRotate: 0,
  //   grayscale: 0,
  //   sepia: 0,
  //   rotate: 0,
  //   verticalScale: 1,
  //   horizontalScale: 1,
  //   naturalHeight: false,
  //   naturalWidth: false
  // });

  // Required variables and constants
  //? Maybe move these to a separate file
  let currentDPI = 300;
  const INCH_TO_CM = 2.54;
  const INTEGER_ROUNDING_FACTOR = 2.54 / 2.5; /// Factor to round cm to px conversion, preserving aspect ratio.
  const inchToCm = (inch) => (inch * INCH_TO_CM);
  const cmToPx = (cm) => (((cm * currentDPI) / INCH_TO_CM) * INTEGER_ROUNDING_FACTOR);
  const inchToPx = (inch) => (cmToPx(inchToCm(inch)));

  // Image and Sheet sizes in px
  const [imageSizes, setImageSizes] = useState([
    {
      "name": '3cm X 4cm',
      "width": cmToPx(3), // 0.05cm less than said size to preserve gap between images
      "height": cmToPx(3.9), // same reason as above
    },
    {
      "name": '3.5cm X 4.5cm',
      "width": cmToPx(3.5),
      "height": cmToPx(4.5),
    },
    {
      "name": '2.5cm X 3.5cm',
      "width": cmToPx(2.5),
      "height": cmToPx(3.4),
    }

  ])
  const [sheetSizes, setSheetSizes] = useState([
    {
      "name": "A4 Size Paper",
      "width": cmToPx(21),
      "height": cmToPx(29.7)
    },
    {
      "name": "A3 Size Paper",
      "width": cmToPx(29.7),
      "height": cmToPx(42)
    },
    {
      "name": "8 X 12 inch Sheet",
      "width": cmToPx(20.32),
      "height": cmToPx(30.48)
    }
  ]);


  const generateResultImage = () => {
    //mycode
    const image = { imageUrl: imageFile };
    const isBordered = false;
    //mycode

    if (!image.imageUrl) return;
    setIsResultLoading(true);
    // applyChangesToImage();
    var ppSizeObj = imageSizes.find(imageSize => imageSize.name === ppSize)
    var ppSheetSizeObj = sheetSizes.find(sheetSize => sheetSize.name === ppSheetSize)
    //mycode
    const selectedImageSize = { width: ppSizeObj.width, height: ppSizeObj.height }
    const selectedSheetSize = { width: ppSheetSizeObj.width, height: ppSheetSizeObj.height }
    //mycode

    const columnGap = 15; // Gap between images in a column (px)
    const rowGap = 30; // Gap between images in a row (px)
    const noOfColumns = Math.floor(selectedSheetSize.width / (selectedImageSize.width + (columnGap * 2)));
    const noOfRows = Math.floor(selectedSheetSize.height / (selectedImageSize.height + rowGap));

    const resultImageCanvas = document.createElement('canvas');
    const resultImageCtx = resultImageCanvas.getContext('2d');
    resultImageCanvas.width = selectedSheetSize.width;
    resultImageCanvas.height = selectedSheetSize.height;
    resultImageCtx.fillStyle = 'white';
    resultImageCtx.fillRect(0, 0, resultImageCanvas.width, resultImageCanvas.height);

    const inputImage = new Image();
    inputImage.onload = () => {
      // Adjust and center the image to fit the selected image size
      const inputImageCanvas = document.createElement('canvas');
      const inputImageCtx = inputImageCanvas.getContext('2d');
      let newWidth, newHeight, x, y;

      newWidth = selectedImageSize.width;
      newHeight = (inputImage.naturalHeight / inputImage.naturalWidth) * selectedImageSize.width;
      x = 0;
      y = -((newHeight / 2) - (selectedImageSize.height / 2));
      if (newHeight < selectedImageSize.height) {
        newWidth = (inputImage.naturalWidth / inputImage.naturalHeight) * selectedImageSize.height;
        newHeight = selectedImageSize.height;
        x = -((newWidth / 2) - (selectedImageSize.width / 2));
        y = 0;
      }

      inputImageCanvas.width = selectedImageSize.width;
      inputImageCanvas.height = selectedImageSize.height;
      inputImageCtx.drawImage(inputImage, x, y, newWidth, newHeight);
      if (isBordered) { // Add border to the image
        let borderWidth = 5; // Border width (px)

        // Adjust border width according to the size of the image
        if ((selectedImageSize.width < 10) || (selectedImageSize.height < 10)) borderWidth = 0;
        else if ((selectedImageSize.width < 30) || (selectedImageSize.height < 30)) borderWidth = 1;

        const borderedInputImageCanvas = document.createElement('canvas');
        const borderedInputImageCtx = borderedInputImageCanvas.getContext('2d');
        borderedInputImageCanvas.width = selectedImageSize.width;
        borderedInputImageCanvas.height = selectedImageSize.height;
        borderedInputImageCtx.fillStyle = 'black';
        borderedInputImageCtx.fillRect(0, 0, borderedInputImageCanvas.width, borderedInputImageCanvas.height);

        borderedInputImageCtx.drawImage(
          inputImageCanvas,
          borderWidth,
          borderWidth,
          selectedImageSize.width - (borderWidth * 2),
          selectedImageSize.height - (borderWidth * 2)
        );

        inputImageCtx.drawImage(borderedInputImageCanvas, 0, 0, selectedImageSize.width, selectedImageSize.height);
      }

      // Draw the input image on the result canvas
      for (let i = 0; i < noOfColumns; i++) {
        for (let j = 0; j < noOfRows; j++) {
          resultImageCtx.drawImage(
            inputImageCanvas,
            (i * (selectedImageSize.width + columnGap)) + (columnGap * (i + 1)),
            (j * (selectedImageSize.height + rowGap)) + rowGap,
            selectedImageSize.width,
            selectedImageSize.height
          );
        }
      }
      setResultImage(resultImageCanvas.toDataURL('image/png'));
      setIsDownloadDisabled(false);
      setIsResultLoading(false);


    }
    inputImage.src = image.imageUrl;


  }


  const downloadImage = () => {
    if (!resultImage) return;
    const link = document.createElement('a');
    link.href = resultImage;
    link.download = 'result.png';
    link.click();
  }


  const handleChangeInput = (e) => {
    setImageFile(URL.createObjectURL(e.target.files[0]))
  }

  useEffect(() => {

    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_NudNsuNY1VBV2O";
      // script.dataset.payment_button_id = "pl_NuXrnaEhpD15Ng";

      rzpPaymentForm.appendChild(script);
    }

  }, []);

  return (
    <Container id="SIE" sx={{ marginTop: '1rem', marginBottom: '4rem', padding: { xs: 0, sm: '10px' } }}>
      <Box sx={{ textAlign: 'left', marginBottom: '1rem', marginTop: '0rem', padding: '0rem', color: '#1976d2' }}>
        <h2># Passport Photo Generation</h2>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          
        {imageFile ? <>
            <Button sx={{
              border: '1px solid rgba(25, 118, 210, 0.5)',
              color: '#1976d2'
            }}
              onClick={() => uploadInputRef2.current && uploadInputRef2.current.click()}
            >
              Upload New Image
            </Button>
            <input onChange={handleChangeInput} ref={uploadInputRef2} type="file" accept="image/*" hidden />
          </>
            :
            <></>
          }
          <StyleBox
            onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
          >
            {imageFile ? <CropImage onClick={(e) => {
              e.preventDefault();
              console.log('Button Clicked');
            }} imageFile={imageFile} setImageFile={setImageFile} /> : <p>Upload Image</p>}

          </StyleBox>
          {imageFile ? <></> :
            <input onChange={handleChangeInput} ref={uploadInputRef} type="file" accept="image/*" hidden />
          }

        </Grid>

        <Grid item xs={12} sm={6} >

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Passport Photo Properties</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                  <InputLabel id="lblPPSize" sx={{ fontSize: '14px' }}>Passport-Photo Size</InputLabel>
                  <Select
                    labelId="lblPPSize"
                    id="selectPPSheetSize"
                    value={ppSize}
                    label="Passport-Photo Size"
                    onChange={handlePPSizeChange}
                  >
                    <MenuItem value={'2.5cm X 3.5cm'}>2.5cm X 3.5cm</MenuItem>
                    <MenuItem value={'3cm X 4cm'}>3cm X 4cm</MenuItem>
                    <MenuItem value={'3.5cm X 4.5cm'}>3.5cm X 4.5cm</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                  <InputLabel id="lblPPSheetSize" sx={{ fontSize: '14px' }}>Passport-Photo Sheet Size</InputLabel>
                  <Select
                    labelId="lblPPSheetSize"
                    id="selectPPSheetSize"
                    value={ppSheetSize}
                    label="Passport-Photo Sheet Size"
                    onChange={handlePPSheetSizeChange}
                  >
                    <MenuItem value={'A4 Size Paper'}>A4 Size Paper</MenuItem>
                    <MenuItem value={'A3 Size Paper'}>A3 Size Paper</MenuItem>
                    <MenuItem value={'8 X 12 inch Sheet'}>8 X 12 inch Sheet</MenuItem>


                  </Select>
                </FormControl>
              </Box>
              {/* <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography> */}
              {/* <FilterTabs />
              {tabFilter === 'instaFilter' ? <InstaFitler /> : <CustomFilter />} */}
            </AccordionDetails>
          </Accordion>
          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Crop</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CropImage></CropImage>
            </AccordionDetails>
          </Accordion> */}
          <Button onClick={generateResultImage} disabled={!imageFile} variant="contained" fullWidth>Generate Passport Photo Sheet</Button>
          <form id="rzp_payment_form"></form>
          <Box id="download_button"></Box>
          {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_NuXANDhFhriho5" async> </script> </form> */}
          {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_NuXW2MlUx2RDhl" async> </script> </form> */}
          <Button onClick={downloadImage} disabled={!resultImage} variant="contained" fullWidth>Download Passport Photo Sheet</Button>

        </Grid>
      </Grid>



      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className='generate-image-section-main'>
            {
              resultImage && !isResultLoading ?
                <img className="result-image"
                  src={resultImage}
                  alt='Result'
                />
                :
                <div>
                  {isResultLoading ? 'Generating...' : 'Click \'Donate\' to support us'}
                </div>
            }
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
