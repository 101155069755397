import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';

import { Outlet, Link } from "react-router-dom";
import ChatBot from 'react-simple-chatbot';
import Partners from './Pages/Partners/Partners';

const StudioTitle = "Online Studio";
const pages = ['Single Image Editor', 'Passport Photo Generation', 'Integration Details'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //ChatBot
  const BotRedirect = ({ url, message }) => {
    return (
      <div>
        <a href={url} target="_blank">
          {message}
        </a>
      </div>
    );
  };

  //ChatBot
  const steps = [
    {
      id: '1',
      message: 'Hi, I am ChatBot',
      trigger: '2',
    },
    {
      id: '2',
      message: 'What is your name?',
      trigger: '3',
    },
    {
      id: '3',
      user: true,
      trigger: '4.1',
    },
    {
      id: '4.1',
      message: 'Hi {previousValue}!',
      trigger: '4',
    },
    {
      id: '4',
      message: 'Please select one of the service you want to visit?',
      trigger: '5',
    },
    {
      id: "5",
      options: [
        { value: 1, label: "Single Image Editor", trigger: "6" },
        { value: 2, label: "Bulk Image Editor", trigger: "7" },
        { value: 3, label: "B2B Pricing", trigger: "8" },
      ]
    },
    {
      id: "6",
      component: (
        <BotRedirect
          message="Single Image Editor URL"
          url="/Single%20Image%20Editor"
        />
      ),
      trigger: "4"
    },
    {
      id: "7",
      component: (
        <BotRedirect
          message="Bulk Image Editor URL"
          url="/Bulk%20Image%20Editor"
        />
      ),
      trigger: "4"
    },
    {
      id: "8",
      component: (
        <BotRedirect
          message="B2B Pricing"
          url="/B2B Pricing"
        />
      ),
      trigger: "4"
    }
  ];


  return (
    <>
      <React.StrictMode>
        <Outlet />
      </React.StrictMode>
    </>
  );
}
export default ResponsiveAppBar;