import { CssBaseline } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <>    <CssBaseline >
    <App />
  </CssBaseline>
  </>
  // </React.StrictMode>
)
